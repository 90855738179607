.wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	// padding: 20px 0px;
}
.review-card {
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	width: 350px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}
.img-area {
	width: 80px;
	height: 80px;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 25px;
}
.img-area img {
	width: 100%;
}
.header-content {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.info h4 {
	margin: 0;
	font-size: 22px;
	font-weight: 600;
}
.info p {
	margin: 5px 0 0;
	font-size: 14px;
	font-weight: 400;
	color: #666;
}
.single-review p {
	text-align: justify;
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.7;
	color: #333;
}
.review-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	font-size: 14px;
	color: #666;
}
.rating {
	display: flex;
}
.rating span {
	color: #000;
	font-size: 18px;
}
.rating span.active {
	color: #fbc02d;
}
@media screen and (max-width: 767px) {
	.wrapper {
		flex-direction:unset;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}
	.review-card {
		width: 85%;
	}
}

@media screen and (min-width:320px) and (max-width:425px) {
	.wrapper{
		gap: 10px;
	}
    .review-card{
        width: 90%;
    }
}

