@import '../../mixins_colors.scss';

.row {
    margin: auto;
    // border: 1px solid red;
    height: auto;
    width: 70rem;
    padding: 30px 0px;
    @include flex(baseline, center);

    .footer-item {
        padding: 0px 40px;
        height: auto;

        h3 {
            margin-bottom: 20px;
            font-weight: 700;
        }

        img {
            width: 170px;
            display: block;

            &:nth-child(2){
                margin-bottom: 20px;
            }
        }

        .wat-iskustva-img{ margin: 0px;}

        p {
            text-align: justify;
        }

        a {
            color: black;
            margin: 10px;
        }
    }

    .border-item {
        border-left: 1px solid black;
        border-right: 1px solid black;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .footer {
        height: auto;
        padding: 20px 0px;
    }

    .row {
        width: 100%;
        height: auto;
        padding: 0px;
        @include flex(baseline, space-around);

        .footer-item {
            padding: 0px 0px 0px 20px;
            height: auto;
            width: 33%;
            
            p {
                font-size: 14px;
            }
        }

    }
}

// @media screen and (min-width: 992px) and (max-width: 1024px) {
//     .row {
//         width: 100%;
//         padding: 20px;

//         .footer-item {
//             padding: 0px 20px;

//             h3 {
//                 text-align: center;
//             }

//             img {
//                 display: block;
//                 margin: 20px auto 0px;
//             }

//             &:nth-child(3) {
//                 p {
//                     width: 200px;
//                     margin: 15px auto;
//                 }
//             }
//         }

//         .footer-item {
//             margin: none;
//         }

//         // .border-item {
//         //     border: none;
//         // }
//     }
// }

@media screen and (min-width: 320px) and (max-width: 425px) {
    .footer {
        height: auto;
        display: block;

        .row {
            padding: 20px 0px;
            height: auto;

            .footer-item {
                margin: 10px 0px;
                padding: 0px 0px 0px 10px;
                border: none;
                height: auto;

                &:nth-child(1) {
                    padding-bottom: 10px;

                    img{
                        width: 170px;
                        margin: 0px 20px 0px 0px;
                        display: block;

                        &:nth-child(1){
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
    .row{
        width: 100%;
        height: auto;
        padding: 10px;

        .footer-item{
            padding: 0px;
            padding: 0px;
            border: none;
            height: auto;
        }

        .footer-item:nth-child(2){
            border: none;
        }

        .footer-item:nth-child(3){
            margin: 0px;
        }
    }
}