@import '../mixins_colors.scss';

.hero_container {
    width: 100%;
    height: 550px;
    background-color: $black;
    background-image: url("../../Images/flag.png");
    background-size: cover;
    @include flex(center, center);

    div {
        width: 30%;
    }
}

.hero_text {
    color: white;

    h1 {
        font-weight: 700;
        font-size: 50px;
        letter-spacing: 2px;
    }

    p {
        font-size: 20px;
        padding: 20px 0px;
    }

    .hero-button {
        margin-top: 20px;
        width: 150px;
        text-transform: uppercase;
        font-size: 20px;
    }
}

.home_episodes_wrapper {
    height: auto;
    display: block;
    width: 100%;
    margin: auto;
    margin-bottom: 10px;

    .container {
        margin: 20px auto;
        width: 400px;
        text-align: center;

        h2 {
            font-weight: 700;
            margin: 20px 0px;
        }

        p {
            font-size: 18px;
        }
    }
}

.card-holder_wrapper {
    margin: auto;
    width: 70%;
    display: block;
    padding: 0px 20px;
}

.card_holder {
    margin: auto;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-template-columns: auto auto auto;
}

.featured-episode-card {
    height: 300px;
    width: 300px;
    margin: 10px;
    box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.1);
    background-size: contain;
}

.home_about__content {
    width: 100%;
    height: auto;
    padding: 20px;
    @include flex(flex-start, center);

    // div {
    //     margin: 50px 50px 50px 0px;
    // }

    .about__content {
        width: 500px;
        height: auto;
        padding-left: 20px;

        .about-button{
            margin: 0px;
        }

        p {
            margin: 20px 0px;
            text-align: justify;
        }
    }

    .author {
        width: 400px;
        border-radius: 10px;

        img {
            width: 100%;
            border-radius: 10px;
        }
    }

}

.blue-section {
    background-color: $black;
    padding:10px 0px 20px 0px;
}

.about-button-pre{
    display: block;
    width: 100%;
    margin: auto;

    button{
        margin: 20px auto 0px auto;
        display: block;
    }
}



@media screen and (min-width:768px) and (max-width:1200px) {
    .hero_container{
        height: auto;
        padding: 40px;
        background-position: 50%;

        .hero_text{
            width: 100%;

            p{width: 375px;}
        }
    
    
        .hero-img{
            display: none;
        }
    }
}

@media screen and (min-width:1077px) and (max-width:1200px) {

}

@media screen and (min-width:320px) and (max-width:767px) {

    .hero_container{
        height: auto;
        display: block;
        background-repeat: no-repeat;
        background-position: 70%;

        .hero_text{
            width: 100%;
            padding: 20px;
            height: auto;
            background-color: rgba($color: #000000, $alpha: 0.7);

            h1{
                text-align: center;
                font-size: 40px;
                padding: 20px 0px;
            }

            p{
                text-align: center;
                padding: 20px;
            }

            .hero-button{
                display: block;
                margin: auto;

                a{
                    font-size: 17px;
                }

                button{
                    width: auto;
                }
            }
        }
    }

    .hero-img{
        display: none;
    }

    .home_episodes_wrapper{
        .container{
            width: 100%;
        }

        .card-holder_wrapper{
            width: 100%;
            padding: 0px;
        }

        a{
            width: 45%;
            margin: 5px;
            padding: 0px;
        }
        .featured-episode-card{
            width: 100%;
            height: auto;
            margin: 0px;
        }
    }

    .home_about__content{
        display: block;
        padding: 20px;
        height: auto;

        .about__content{
            width: 100%;
            height: auto;
            margin: 10px 0px;
            padding: 0px;

            h2{
                margin-top: 20px;
            }
        }

        .about-button{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0px;
        }

        .author{
            width: 100%;
            margin: 0px;
        }
    }
}

@media screen and (min-width:320px) and (max-width:550px) {
    .card-holder_wrapper{
        .card_holder{
            a:nth-child(1){ order: 1; }
            a:nth-child(2){ order: 2; }
            a:nth-child(3){ order: 4; }
            a:nth-child(4){ order: 3; }
            a:nth-child(5){ order: 5; }
            a:nth-child(6){ order: 6; }
        }
    }
}

@media screen and (min-width:551px) and (max-width:1024px) {

    .card-holder_wrapper{
        width: 100%;
    }

    .card_holder{
        a{
            width: 30%;
            margin: 5px;

            .featured-episode-card{
                width: 100%;
                margin: 0px;
                height: auto;
            }
        }
    }
}

@media screen and (min-width:1025px) {

    .card-holder_wrapper{
        width: 1000px;
    }
    .card_holder{
        width: 100%;
    }
}

@media screen and (min-width:426px) and (max-width: 767px) {
    .about-button-pre{
        display: block;
    }

    .about__content{
        .about-button{
            display: none;
        }
    }


}

@media screen and (min-width:768px) and (max-width:1024px) {

}

@media screen and (min-width:320px) and (max-width:360px) {
	.hero_container{
		.hero_text{
			padding: 20px 10px;
            
            h1{
                font-size: 35px;
            }

            p{
                font-size: 18px;
                padding: 10px;
            }
		}
	}
}

// @media screen and (min-width:376px) and (max-width:414px) {

// }


// @media screen and (min-width:415px) and (max-width:425px) {

// }