.wrapper-episodes{
    display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	padding: 20px 0px;
}
.episode-card {
	background-color: white;
	border-radius: 10px;
	width: 500px;
    height: auto;
    padding-bottom: 15px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    transition: 0.3s ease;

    &:hover{
        transform: translateY(-10px);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}

.episode-img {
	width: 100%;

    img{
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

.podcast-app-header-content {
	display: flex;
	align-items: center;
}

.episode-header{
	h4{
		color: black;
		font-size: 18px;
        padding: 20px;
		margin: 0px;
	}
}

.center-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
}



@media screen and (min-width:320px) and (max-width:425px) {
    .wrapper-episodes{
        width: 100%;
        padding: 10px;
    }

    .episode-card{
        width: 100%;

        &:hover{
            transform:none;
        }

        h4{
            font-size: 16px;
        }
    }

    .center-button{
        a{
            font-size: 14px;
        }
    }
}