@import '../../mixins_colors.scss';

.navbar-desktop{
    background-color: #fff;
    height: 100px;
    padding: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    @include flex (center, space-between);

    img{
        margin-left: 100px;
        width: 250px;
    }

    ul{
        width: auto;
        margin: 0;
        padding: 0;
        text-align:center;
        margin-right: 100px;
        @include flex(center, space-around);
    }

    ul li{
        display: inline-block;
        margin: 0px 15px;
        font-size: 20px;
        font-weight: 500;
    }

    ul li a{
        color: #000;
        text-decoration: none;
        font-weight: 500;
        // margin: 10px 15px;

        &:hover{
            color: #000;
            font-weight: 600;
        }

        &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background: #000;
            transition: width .3s;
            /* margin: 0px 25px */
        }

        &:hover::after {
            width: 60%;
            transition: width .3s;
        }
    }

    button{
        font-size: 20px;
        width: 170px;
        padding: 7px 10px;
    }
}

@media screen and (min-width:768px) and (max-width:1600px) {
    .navbar-desktop{
        img, ul{
            margin: 0px;
            width: 200px;
        }

        ul{
            margin: 0px;
            @include flex(center, flex-end);
        }

        ul li{
            margin: 0px 15px;
        }

        ul li a{
            font-size: 20px;
        }
    }
}

@media screen and (min-width:320px) and (max-width:767px) {
    .navbar-desktop{
        display: none;

        ul{
            display: none;
        }

        img{
            margin-left: 0px;
        }
    }
}