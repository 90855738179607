.link-wrapper{
    background-color: #016ace;
    height: 100vh;
    width: 100%;

    header{
        padding: 20px;
        img{
            display: block;
            margin: auto;
            border-radius: 50%;
            width: 120px;
        }

        h2{
            color: white;
        }
    }
}

body{
    // background: linear-gradient(to right, #016ace, #ef494b);
    // background-color: black;
}

.link-card {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    border-radius: 5px;
    text-decoration: none;
    color: black;
    background-color: white;
    padding: 10px;
    width: 400px;
    height: auto;
    transition: 0.3s ease;

    img{
        width: 50px;
        // border-radius: 50%;
        // outline: 1px solid red;
    }

    p{
        width: auto;
        margin: 0px auto;
        font-size: 20px;
        text-align: center;
    }
}

.link-footer{
    margin-top: 20px;
    text-align: center;
    color: white;
}

@media screen and (min-width:320px) and (max-width:400px) {
    .link-wrapper{
        width: 100%;
        padding: 0px 10px;
    }

    .link-card{
        width: 100%;
        padding: 5px;

        img{
            width: 40px;
        }

        p{
            font-size: 16px;
        }
    }
}