#offcanvasDarkNavbar{
    width: 70%;
}

body{
    overflow: scroll!important;
}


.navbar-mobile{
    a{
        width: 70%;
    }

    .mobile-navbar-img{
        width: 70px;
    }
}

.offcanvas-header{
    width: 100%;
    justify-content: end;
    padding: 40px;
}

.navbar-nav{
    display: flex;
    align-items: center;

    li{
        display: block;
        margin: auto;
        width: 100%;
    }
    
    a{
        display: block;
        margin: auto;
        width: 100%;

        button{
            width: auto;
        }
    }
}

// BOOTSTRAP RESPONSIVE NAVBAR


.navbar-mobile{
    padding: 15px 0px;
    display: none;
}

.nav-link{
    font-size: 20px;
    color: white;
    text-align: center;
}

.mobile-navbar-img{
    width: 270px;
}

.navbar-toggler{
    width: 70px;

    &:focus{
        box-shadow: none;
    }
}

.offcanvas{
    background-color: $black;
}

.offcanvas-header{
    width: 70px;
}

@media screen and (min-width:320px) and (max-width:767px) {
    .navbar-mobile{
        display: block;
    }
}