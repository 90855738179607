.wrapper-applications {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	// padding: 20px 0px;
}

.podcast-app-card {
	background-color: white;
	border-radius: 10px;
	padding: 10px;
	width: 200px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
	transition: 0.3s ease;

	&:hover {
		transform: translateY(-10px);
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	}
}

.podcast-app-img-area {
	width: 50px;
	height: 50px;
	overflow: hidden;
	border-radius: 50%;
	// margin-right: 20px;

	img {
		width: 100%;
	}
}

.podcast-app-header-content {
	display: flex;
	align-items: center;
}

.podcast-app-info {
	h4 {
		color: black;
		font-size: 20px;
		font-weight: 600;
		margin: 0px;
		padding: 0px 20px;
		// padding: 0px;
	}
}

@media screen and (min-width:341px) and (max-width:650px) {

	.wrapper-applications {
		gap: 10px;
		width: 330px;
		margin: auto;

		a {
			width: auto;

		}
		.podcast-app-info{
			h4{
				font-size: 15px;
			}
		}

	}
}

@media screen and (min-width:650px) and (max-width:767px) {
	.wrapper-applications {
		gap: 10px;

		a {
			width: auto;
		}
	}
}

@media screen and (min-width:768px) and (max-width:1024px) {
	.wrapper-applications {
		gap: 10px;

		a {
			width: auto;
		}

		.podcast-app-card {
			width: auto;
		}

		h4{
			font-size: 20px;
			padding: 0px 20px;
		}

		.podcast-app-img-area {
			width: 40px;
			height: 40px;
			margin: 0px;

			img {
				border-radius: 50%;
			}
		}

	}


}

@media screen and (min-width:320px) and (max-width:767px) {

	.podcast-app-header-content {
		width: 125px;
	}

	.podcast-app-card {
		width: 150px;

		h4 {
			font-size: 17px;
			padding: 0px;
		}

		.podcast-app-img-area {
			width: 25px;
			height: 25px;
			margin-right: 20px;

			img {
				border-radius: 50%;
			}
		}
	}
}

@media screen and (min-width:320px) and (max-width:340px) {
	.wrapper-applications {
		gap: 10px;
		width: 320px;
		margin: auto;

		a {
			width: auto;
		}
	}

	.podcast-app-card{
		width: 140px;

		h4{font-size: 15px;}
	}
}
