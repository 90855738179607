@import '../CSS/Home/main_home.scss';
@import '../CSS/General/main_general.scss';
@import '../CSS/mixins_colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    font-family: "Poppins", serif;
    overflow: scroll;
    background-color: white;
}

a{
    text-decoration: none;
}

.row{
    width: 100%;
}

.error404{
    height: 80vh;
    @include flex(center, center);


    .error-message{
        text-align: center;
        h1{
            font-size: 10rem;
        }
        p{
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: 768px) {
    
}